#contactmain {
    height: 355px;
    color: rgb(75, 75, 75);
    width: 100%;
    background-image: linear-gradient(to top left,  rgb(238, 255, 0), rgb(245, 255, 112));
    position: relative;
}
.contactbox {
    height: 250px;
    width: calc(100% - 100px);
    border: solid 5px rgb(75, 75, 75);
    background-image: linear-gradient(to top right rgb(238, 255, 0), rgb(238, 255, 0));
    color: rgb(75, 75, 75);
    font-style: italic;
    font-family: 'Roboto';
    font-size: 50px;
    font-weight: 900;
    border-radius: 8px;
    margin: auto;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 7px 8px 5px #0505055d;
    text-align: center;
}
.contactnames {
    font-family: 'Permanent Marker';
    font-size: 32px;
}
p {
    font-size: 25px;
    padding: 5px 0px;
}
.emails {
    padding-top: 10px;
    padding-left: 5px;
}
.indvcontacts {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 15px;
}
.contacttitle {
    font-family: 'Montserrat';
    font-weight: 900;
    letter-spacing: 2px;
}
.contactinfoc, .contactinfow, .topsection{
    display: flex;
}
.createdby {
    position: absolute;
    bottom: 5px;
    right: 15px;
    font-style: italic;
    font-weight: 500;
    color: rgb(58, 58, 58);
}
@media (max-width: 1050px){
    .indvcontacts {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #contactmain {
        height: 400px;
    }
    .contactbox {
        height: 300px;
    }
}
@media (max-width: 600px){
    .contactinfoc, .contactinfow {
        flex-direction: column;
    }
    #contactmain {
        height: 450px;
    }
    .contactbox {
        height: 350px;
    }
}
@media (max-width: 450px){
    .contacttitle {
        font-size: 40px;
    }
}
@media (max-width: 410px) {
    .contacttitle {
        font-size: 35px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .contactnames {
        font-size: 25px;
    }
    .emails {
        font-size: 20px;
    }
}
@media (max-width: 375px){
    .contactbox {
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media (max-width: 350px){
    .contacttitle {
        font-size: 40px;
    }
}