.navbarmainh {
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    width: 100%;
    height: 75px;
    padding: 25px 0px;
    transition: padding 0.9s ease-in-out, border-bottom 1.5s ease-in-out;
    display: flex;
    color: transparent;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    justify-content: space-around;
    align-items: center;
    font-size: 30px;
    border-bottom: solid 5px;
    border-bottom-color: rgb(31, 31, 31);
    z-index: 100;
}
.navbarmainsh {
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    padding: 0px 0px;
    transition: padding 1s ease-in-out, border-bottom 1.5s ease-in-out;
    text-shadow: -2px 0 rgb(107, 107, 107), 0 2px rgb(107, 107, 107), 0 -2px rgb(107, 107, 107);
    width: 100%;
    height: 75px;
    display: flex;
    color: white;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    font-size: 30px;
    border-bottom: solid 0px;
    border-bottom-color: rgb(31, 31, 31);
    z-index: 100;
}
.logoh, .logosh {
    padding-top: 13px;
    flex: 1;
}
.rowleylogo {
    max-height: 100px;
    width: auto;
    padding-bottom: 10px;
    transition: 1.1s ease-in-out;
}
.rowleylogosmall {
    max-height: 65px;
    width: auto;
    padding-bottom: 10px;
    transition: all 1.2s ease-in-out, transform 0.5s ease-in-out;
}
.rowleylogosmall:hover {
    transform: scale(1.1);
}
.logosh {
    cursor: pointer;
}

.completenavbar {
    position: sticky;
}
@media (max-width: 625px){
    .navbarmainh {
        padding: 10px 0;
    }
    .rowleylogo {
        max-height: 85px;
    }
}

