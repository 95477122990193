.landingcovermain {
    background-image: url('../Images/RPI\ Home\ Image.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;
}
.landingcovermainsunset {
    background-image: url('../Images/Desert\ Sunset.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;
}
.landingcovermainnight {
    background-image: url('../Images/Desert\ Night.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;
}
.landingcovertwomain {
    background-color: rgb(206, 206, 206);
    height: 120vh;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -98;
}
@media (max-width: 750px){
    .landingcovertwomain {
        height: 120vh;
    }
}
@media (max-height: 625px){
    .landingcovertwomain {
        height: 130vh;
    }
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 115vh;
    }
}
@media (max-height: 575px){
    .landingcovertwomain {
        height: 140vh;
    }
}
@media (max-height: 525px){
    .landingcovertwomain {
        height: 150vh;
    }
}
@media (max-height: 500px){
    .landingcovertwomain {
        height: 160vh;
    }
}
@media (max-height: 490px){
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 130vh;
    }
}
@media (max-height: 475px){
    .landingcovertwomain {
        height: 170vh;
    }
}
@media (max-height: 425px){
    .landingcovertwomain {
        height: 180vh;
    }
}
@media (max-height: 400px){
    .landingcovertwomain {
        height: 190vh;
    }
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 140vh;
    }
}
@media (max-height: 375px){
    .landingcovertwomain {
        height: 200vh;
    }
}
@media (max-height: 350px){
    .landingcovertwomain {
        height: 210vh;
    }
}
@media (max-height: 325px){
    .landingcovertwomain {
        height: 220vh;
    }
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 150vh;
    }
}
@media (max-height: 300px){
    .landingcovertwomain {
        height: 230vh;
    }
}