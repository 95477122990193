.bottomnavbarmain {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75px;
    width: 100%;
    background-color: rgba(27, 27, 27, 0.616);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(65, 64, 64);
    font-size: 35px;
    font-family: 'Montserrat';
    transition: text-shadow 0.7s ease-in-out;
    font-style: italic;
}
.bottomnavbarmainsticky {
    position: fixed;
    top: 75px;
    height: 75px;
    width: 100%;
    z-index: 100;
    transition: text-shadow 0.7s ease-in-out, background-color 3s ease-in-out;    
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(65, 64, 64);
    font-size: 35px;
    font-family: 'Montserrat';
    font-style: italic;
}
.icons {
    height: 30px;
    width: auto;
    margin-top: 15px;
    margin-bottom: 10px;
}
.abouttag, .projectstag, .contacttag {
    display: flex;
    flex-direction: column;
    color: rgb(192, 192, 192);
    z-index: 99;
    padding-left: 25px;
    transition: 0.3s ease-in-out;
}
.abouttagactive, .projectstagactive, .contacttagactive {
    display: flex;
    flex-direction: column;
    color: rgb(255, 1, 1);
    z-index: 99;
    padding-left: 25px;
    transition: 0.3s ease-in-out;
}
.abouttag:hover, .projectstag:hover, .contacttag:hover {
    color: rgb(122, 122, 122);
    transform: scale(1.05) skewX(-2deg) translateX(2px);
    transition: 0.3s ease-in-out;
}
.abouttagactive:hover, .projectstagactive:hover, .contacttagactive:hover {
    transform: scale(1.05) skewX(-2deg) translateX(2px);
    transition: 0.3s ease-in-out;
}
#bottomnavbarcontainer {
    position: relative;
}
.transitionnavbarhidden {
    position: absolute;
    height: 75px;
    width: 100%;
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    opacity: 0;
    transition: 0.5s ease-in-out;
    z-index: -1;
}
.transitionnavbar {
    position: absolute;
    height: 75px;
    width: 100%;
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    opacity: 1;
    z-index: -1;
}
@media (max-width: 575px){
    .bottomnavbarmain, .bottomnavbarmainsticky {
        font-size: 25px;
    }
    .abouttag, .projectstag, .contacttag, .abouttagactive, .projectstagactive, .contacttagactive {
        padding-left: 0px;
    }
    .abouttag:hover, .projectstag:hover, .contacttag:hover, .abouttagactive:hover, .projectstagactive:hover, .contacttagactive:hover {
        transform: none;
    }
}
@media (max-width: 425px){
    .bottomnavbarmain, .bottomnavbarmainsticky {
        font-size: 20px;
    }
}
