html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
  font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	scroll-behavior: smooth;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
button {
    background-color: transparent;
    border: none;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	cursor: pointer;
	padding: 0px;
}
button:focus, a:focus {
	outline: none;
}
.App {
  text-align: center;
  background-color: transparent;
  min-height: 100vh;
  font-family: 'Roboto';
}
a {
	text-decoration: none;
	cursor: pointer;
}

.sectiontwo {
  position: relative;
  text-align: center;
  background-color: transparent;
  min-height: 100vh;
  font-family: 'Roboto';
}
::-webkit-scrollbar {
	width: 12px;
}
::-webkit-scrollbar-track {
	background: #212529;
}
::-webkit-scrollbar-thumb {
	background: rgb(70, 69, 69);
	border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
	background: rgb(156, 151, 151);
}


