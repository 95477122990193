.projectboxmain, .projectboxreversemain {
    height: 450px;
    width: 475px;
    display: flex;
    flex-direction: column;
    color: rgb(180, 176, 176);
    position: relative;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.projectboxreversemain {
    height: 460px;
}
.projectboxmain, .projectboxreversemain {
    background-color: transparent;
}
.projectname {
    font-size: 28px;
    padding-top: 25px;
    color: rgb(78, 78, 78);
    padding-bottom: 10px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: italic;
    padding-left: 10px;
    padding-right: 10px;
}
.projectaddress {
    padding-bottom: 25px;
}
.projectimage {
    height: 355px;
    width: 100%;
}
img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
@media (max-width: 1075px){
    .projectboxmain {
        margin-bottom: 125px;
    }
    .projectboxreversemain {
        margin-bottom: 25px;
    }
}
@media (max-width: 500px){
    .projectboxmain, .projectboxreversemain {
        height: 475px;
        width: 100vw;
    }
    .projectimage {
        height: 370px;
    }
}
@media (max-width: 400px){
    .projectboxmain, .projectboxreversemain {
        height: 410px;
    }
    .projectimage {
        height: 310px;
    }
    .projectname {
        font-size: 25px;
    }
    .projecttitle {
        font-size: 45px;
    }
}
@media (hover: hover) and (pointer: fine){
    .projectboxmain:hover, .projectboxreversemain:hover {
        transform: translateY(-10px);
        transition: 0.7s;
    }
    .projectboxmain:hover {
        box-shadow: 0px 14px 10px 10px rgb(161, 161, 161);
    }
    .projectboxreversemain:hover {
        box-shadow: 0px 8px 12px 10px rgb(161, 161, 161);
    }
}