#projectsmain {
    padding-top: 100px;
    min-height: calc(100vh - 75px);
    background-image: linear-gradient(to top left, rgb(255, 255, 255) 40%, rgb(192, 192, 192));
    background-color: transparent;
    z-index: 1;
    position: relative;
}
#projects {
    position: absolute;
    top: -150px;
}
.projecttitle {
    font-size: 50px;
    font-weight: 700;
    color: rgb(65, 65, 65);
    font-style: italic;
    font-family: 'Montserrat';
    font-weight: 900;
    letter-spacing: 2px;
}
.projecttitledescr {
    padding-top: 10px;
    font-style: italic;
    color: rgb(65, 65, 65);
    letter-spacing: 1px;
}
.projectboxblockone, .projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
    display: flex;
    justify-content: space-around;
    padding: 0 50px;
}
.projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
    padding-top: 100px;
}
.projectboxblockone {
    padding-top: 200px;
}
.projectboxblockeight {
    padding-bottom: 300px;
}
@media (max-width: 1075px){
    .projectboxblockone, .projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
        flex-direction: column;
    }
    .projectscontainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1000px){
    .projectboxblockeight {
        padding-bottom: 150px;
    }
}
@media (max-width: 750px){
    #projects {
        top: -150px;
    }
}
@media (max-width: 580px){
    .projectboxblockone, .projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width: 500px){
    .projectboxblockone {
        padding-top: 150px;
    }
}
