#aboutmain {
    height: 120vh;
    background-image: linear-gradient(to top left,  rgb(207, 3, 3) 5%, rgb(66, 20, 20));
    color: rgb(236, 191, 191);
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
#about {
    width: 100%;
    position: absolute;
    top: -150px;
}
.abouttitle {
    font-size: 50px;
    padding-top: 100px;
    color: rgb(236, 191, 191);
    font-style: italic;
    font-family: 'Montserrat';
    font-weight: 900;
    letter-spacing: 2px;
}
.abouttitledescr {
    padding-top: 10px;
    font-style: italic;
    color: rgb(236, 191, 191);
}
.timelinecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 75%;
}
.timelineleft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 550px;
    padding-left: 10px;
    padding-right: 30px;
}
.timelineright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 550px;
    padding-right: 10px;
    padding-left: 30px;
}
.timelinecircles {
    flex: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 500px;
    width: 10%;
}
#timelinelength {
    width: 5px;
    margin: 5px 0px;
    height: 100px;
    transition: 1s ease-in-out;
    background-color: rgb(46, 46, 46);
    border-radius: 3px;
}
#timelinelengthactive {
    width: 5px;
    margin: 5px 0px;
    transition: 1s ease-in-out;
    height: 100px;
    background-color: rgb(179, 179, 179);
    border-radius: 3px;
}
#circle {
    width: 13px;
    height: 14px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background-color: white;
    animation-name: reversecircle;
    animation-duration: 1s;
    transition: 0.2s ease-in-out;
    transition-property: width, height, background-color;
}
#circle:hover {
    background-color: rgb(138, 136, 136);
    width: 18px;
    height: 18px;
    transition: all 0.5s ease-in-out;
}
#circleactive {
        width: 40px;
        height: 42px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        background-color: rgb(138, 136, 136);
        animation-name: circle;
        animation-duration: 1s;
        border: 3.5px solid rgb(37, 37, 37);
}
.abouttextshown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 2.0s ease-in-out;
    transition-delay: 0.5s;
    opacity: 1;
    width: 100%;
}
.abouttexthidden {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: 0.5s ease-in-out;
    opacity: 0;
}
.extrabottom {
    padding-bottom: 50px;
}
.extratop {
    padding-top: 50px;
}
@keyframes circle {
    0% {
        width: 13px;
        height: 14px;
        background-color: white;
    }
    50% {
        width: 50px;
        height: 57px;
        background-color: rgb(112, 112, 112);
        border: 4px solid rgb(39, 39, 39);
    }
}
@keyframes reversecircle {
    0%{
        width: 40px;
        height: 40px;
        background-color: rgb(112, 112, 112);
        animation-name: circle;
        animation-duration: 1s;
        border: 4px solid rgb(37, 37, 37);
    }
    50%{
        width: 50px;
        height: 60px;
        background-color: rgb(112, 112, 112);
        border: 4px solid rgb(39, 39, 39);
    }
}
@media (max-width: 750px){
    .abouttexthidden, .abouttextshown {
        font-size: 20px;
    }
    .aboutmain {
        height: 120vh;
    }
}
@media (max-width: 560px){
    .abouttexthidden, .abouttextshown {
        font-size: 16px;
    }
}
@media (max-width: 450px){
    .abouttexthidden, .abouttextshown {
        font-size: 14px;
    }
}
@media (max-height: 625px){
    #aboutmain {
        height: 130vh;
    }
}
@media (max-height: 575px){
    #aboutmain {
        height: 140vh;
    }
}
@media (max-height: 525px){
    #aboutmain {
        height: 150vh;
    }
}
@media (max-height: 500px){
    #aboutmain {
        height: 160vh;
    }
}
@media (max-height: 475px){
    #aboutmain {
        height: 170vh;
    }
}
@media (max-height: 425px){
    #aboutmain {
        height: 180vh;
    }
}
@media (max-height: 400px){
    #aboutmain {
        height: 190vh;
    }
}
@media (max-height: 375px){
    #aboutmain {
        height: 200vh;
    }
}
@media (max-height: 350px){
    #aboutmain {
        height: 210vh;
    }
}
@media (max-height: 325px){
    #aboutmain {
        height: 220vh;
    }
}
@media (max-height: 300px){
    #aboutmain {
        height: 230vh;
    }
}

