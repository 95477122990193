#landingmain {
    color: rgb(83, 8, 8);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    position: absolute;
    top: 80px;
    width: 100%;
    min-height: 20vh;
}
.rpititle {
    font-family: 'Mr Dafoe', cursive;
    width: 100%;
    font-size: 150px;
    text-shadow: -2px 0 rgb(255, 0, 0), 0 2px rgb(255, 0, 0);
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    padding-left: 25px;
}
.plumbinginc {
    font-size: 45px;
    text-shadow: -1px 0 rgb(58, 55, 55), 0 1px rgb(58, 55, 55);
    color: white;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    padding-top: 70px;
}
.established {
    font-size: 15px;
    color: rgb(97, 97, 97);
    text-shadow: none;
    font-weight: 100;
    padding-top: 10px;
    padding-right: 100px;
    font-family: 'Poppins', sans-serif;
}
.secondarytitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
@media (max-width: 750px){
    .rpititle {
        font-size: 125px;
    }
    .plumbinginc {
        font-size: 30px;
        padding-left: 15px;
        padding-top: 55px;
    }
    .established {
        padding-top: 7px;
        padding-right: 0px;
        padding-left: 100px;
    }
    .secondarytitle {
        padding-top: 10px;
    }
}
@media (max-width: 625px){
    #landingmain {
        top: 110px;
    }
}
@media(max-width: 610px){
    .rpititle {
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 25px;
        margin-left: 0px;
        position: relative;
        font-size: 100px;
   }
   .plumbinginc {
       font-size: 25px;
   }
   .primarytitle {
       position: absolute;
       left: 35px;
       top: 13px;
   }
    .secondarytitle {
        position: absolute;
        left: 30px;
        top: 40px;
    }
    .established {
        color: rgb(51, 51, 51);
        text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255);
        padding-left: 0px;
        font-size: 13px;
    }
}
@media (max-width: 355px){
    .rpititle {
        font-size: 100px;
        padding-left: 0px;
    }
    .plumbinginc {
        font-size: 20px;
    }
    .established {
        text-shadow: none;
    }
    .primarytitle {
        left: 15px;
    }
    .secondarytitle {
        left: 20px;
        top: 30px;
    }
}

