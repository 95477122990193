body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
  font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	scroll-behavior: smooth;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
button {
    background-color: transparent;
    border: none;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	cursor: pointer;
	padding: 0px;
}
button:focus, a:focus {
	outline: none;
}
.App {
  text-align: center;
  background-color: transparent;
  min-height: 100vh;
  font-family: 'Roboto';
}
a {
	text-decoration: none;
	cursor: pointer;
}

.sectiontwo {
  position: relative;
  text-align: center;
  background-color: transparent;
  min-height: 100vh;
  font-family: 'Roboto';
}
::-webkit-scrollbar {
	width: 12px;
}
::-webkit-scrollbar-track {
	background: #212529;
}
::-webkit-scrollbar-thumb {
	background: rgb(70, 69, 69);
	border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
	background: rgb(156, 151, 151);
}



.navbarmainh {
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(46, 46, 46)), color-stop(rgb(0, 0, 0)), to(rgb(46, 46, 46)));
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    width: 100%;
    height: 75px;
    padding: 25px 0px;
    -webkit-transition: padding 0.9s ease-in-out, border-bottom 1.5s ease-in-out;
    transition: padding 0.9s ease-in-out, border-bottom 1.5s ease-in-out;
    display: flex;
    color: transparent;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    justify-content: space-around;
    align-items: center;
    font-size: 30px;
    border-bottom: solid 5px;
    border-bottom-color: rgb(31, 31, 31);
    z-index: 100;
}
.navbarmainsh {
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(46, 46, 46)), color-stop(rgb(0, 0, 0)), to(rgb(46, 46, 46)));
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    padding: 0px 0px;
    -webkit-transition: padding 1s ease-in-out, border-bottom 1.5s ease-in-out;
    transition: padding 1s ease-in-out, border-bottom 1.5s ease-in-out;
    text-shadow: -2px 0 rgb(107, 107, 107), 0 2px rgb(107, 107, 107), 0 -2px rgb(107, 107, 107);
    width: 100%;
    height: 75px;
    display: flex;
    color: white;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    font-size: 30px;
    border-bottom: solid 0px;
    border-bottom-color: rgb(31, 31, 31);
    z-index: 100;
}
.logoh, .logosh {
    padding-top: 13px;
    flex: 1 1;
}
.rowleylogo {
    max-height: 100px;
    width: auto;
    padding-bottom: 10px;
    -webkit-transition: 1.1s ease-in-out;
    transition: 1.1s ease-in-out;
}
.rowleylogosmall {
    max-height: 65px;
    width: auto;
    padding-bottom: 10px;
    -webkit-transition: all 1.2s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: all 1.2s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: all 1.2s ease-in-out, transform 0.5s ease-in-out;
    transition: all 1.2s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.rowleylogosmall:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.logosh {
    cursor: pointer;
}

.completenavbar {
    position: -webkit-sticky;
    position: sticky;
}
@media (max-width: 625px){
    .navbarmainh {
        padding: 10px 0;
    }
    .rowleylogo {
        max-height: 85px;
    }
}


#landingmain {
    color: rgb(83, 8, 8);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    position: absolute;
    top: 80px;
    width: 100%;
    min-height: 20vh;
}
.rpititle {
    font-family: 'Mr Dafoe', cursive;
    width: 100%;
    font-size: 150px;
    text-shadow: -2px 0 rgb(255, 0, 0), 0 2px rgb(255, 0, 0);
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    padding-left: 25px;
}
.plumbinginc {
    font-size: 45px;
    text-shadow: -1px 0 rgb(58, 55, 55), 0 1px rgb(58, 55, 55);
    color: white;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    padding-top: 70px;
}
.established {
    font-size: 15px;
    color: rgb(97, 97, 97);
    text-shadow: none;
    font-weight: 100;
    padding-top: 10px;
    padding-right: 100px;
    font-family: 'Poppins', sans-serif;
}
.secondarytitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
@media (max-width: 750px){
    .rpititle {
        font-size: 125px;
    }
    .plumbinginc {
        font-size: 30px;
        padding-left: 15px;
        padding-top: 55px;
    }
    .established {
        padding-top: 7px;
        padding-right: 0px;
        padding-left: 100px;
    }
    .secondarytitle {
        padding-top: 10px;
    }
}
@media (max-width: 625px){
    #landingmain {
        top: 110px;
    }
}
@media(max-width: 610px){
    .rpititle {
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 25px;
        margin-left: 0px;
        position: relative;
        font-size: 100px;
   }
   .plumbinginc {
       font-size: 25px;
   }
   .primarytitle {
       position: absolute;
       left: 35px;
       top: 13px;
   }
    .secondarytitle {
        position: absolute;
        left: 30px;
        top: 40px;
    }
    .established {
        color: rgb(51, 51, 51);
        text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255);
        padding-left: 0px;
        font-size: 13px;
    }
}
@media (max-width: 355px){
    .rpititle {
        font-size: 100px;
        padding-left: 0px;
    }
    .plumbinginc {
        font-size: 20px;
    }
    .established {
        text-shadow: none;
    }
    .primarytitle {
        left: 15px;
    }
    .secondarytitle {
        left: 20px;
        top: 30px;
    }
}


#contactmain {
    height: 355px;
    color: rgb(75, 75, 75);
    width: 100%;
    background-image: -webkit-gradient(linear,  right bottom, left top,  from(rgb(238, 255, 0)), to(rgb(245, 255, 112)));
    background-image: linear-gradient(to top left,  rgb(238, 255, 0), rgb(245, 255, 112));
    position: relative;
}
.contactbox {
    height: 250px;
    width: calc(100% - 100px);
    border: solid 5px rgb(75, 75, 75);
    background-image: -webkit-gradient(linear, left bottom, right top, from(rgb(238, 255, 0)));
    background-image: linear-gradient(to top right rgb(238, 255, 0), rgb(238, 255, 0));
    color: rgb(75, 75, 75);
    font-style: italic;
    font-family: 'Roboto';
    font-size: 50px;
    font-weight: 900;
    border-radius: 8px;
    margin: auto;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 7px 8px 5px #0505055d;
    text-align: center;
}
.contactnames {
    font-family: 'Permanent Marker';
    font-size: 32px;
}
p {
    font-size: 25px;
    padding: 5px 0px;
}
.emails {
    padding-top: 10px;
    padding-left: 5px;
}
.indvcontacts {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 15px;
}
.contacttitle {
    font-family: 'Montserrat';
    font-weight: 900;
    letter-spacing: 2px;
}
.contactinfoc, .contactinfow, .topsection{
    display: flex;
}
.createdby {
    position: absolute;
    bottom: 5px;
    right: 15px;
    font-style: italic;
    font-weight: 500;
    color: rgb(58, 58, 58);
}
@media (max-width: 1050px){
    .indvcontacts {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #contactmain {
        height: 400px;
    }
    .contactbox {
        height: 300px;
    }
}
@media (max-width: 600px){
    .contactinfoc, .contactinfow {
        flex-direction: column;
    }
    #contactmain {
        height: 450px;
    }
    .contactbox {
        height: 350px;
    }
}
@media (max-width: 450px){
    .contacttitle {
        font-size: 40px;
    }
}
@media (max-width: 410px) {
    .contacttitle {
        font-size: 35px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .contactnames {
        font-size: 25px;
    }
    .emails {
        font-size: 20px;
    }
}
@media (max-width: 375px){
    .contactbox {
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media (max-width: 350px){
    .contacttitle {
        font-size: 40px;
    }
}
#aboutmain {
    height: 120vh;
    background-image: -webkit-gradient(linear,  right bottom, left top,  color-stop(5%, rgb(207, 3, 3)), to(rgb(66, 20, 20)));
    background-image: linear-gradient(to top left,  rgb(207, 3, 3) 5%, rgb(66, 20, 20));
    color: rgb(236, 191, 191);
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
#about {
    width: 100%;
    position: absolute;
    top: -150px;
}
.abouttitle {
    font-size: 50px;
    padding-top: 100px;
    color: rgb(236, 191, 191);
    font-style: italic;
    font-family: 'Montserrat';
    font-weight: 900;
    letter-spacing: 2px;
}
.abouttitledescr {
    padding-top: 10px;
    font-style: italic;
    color: rgb(236, 191, 191);
}
.timelinecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 75%;
}
.timelineleft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 550px;
    padding-left: 10px;
    padding-right: 30px;
}
.timelineright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 550px;
    padding-right: 10px;
    padding-left: 30px;
}
.timelinecircles {
    flex: 1 1;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 500px;
    width: 10%;
}
#timelinelength {
    width: 5px;
    margin: 5px 0px;
    height: 100px;
    -webkit-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    background-color: rgb(46, 46, 46);
    border-radius: 3px;
}
#timelinelengthactive {
    width: 5px;
    margin: 5px 0px;
    -webkit-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    height: 100px;
    background-color: rgb(179, 179, 179);
    border-radius: 3px;
}
#circle {
    width: 13px;
    height: 14px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background-color: white;
    -webkit-animation-name: reversecircle;
            animation-name: reversecircle;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transition-property: width, height, background-color;
    transition-property: width, height, background-color;
}
#circle:hover {
    background-color: rgb(138, 136, 136);
    width: 18px;
    height: 18px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
#circleactive {
        width: 40px;
        height: 42px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        background-color: rgb(138, 136, 136);
        -webkit-animation-name: circle;
                animation-name: circle;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        border: 3.5px solid rgb(37, 37, 37);
}
.abouttextshown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: opacity 2.0s ease-in-out;
    transition: opacity 2.0s ease-in-out;
    -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
    opacity: 1;
    width: 100%;
}
.abouttexthidden {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    opacity: 0;
}
.extrabottom {
    padding-bottom: 50px;
}
.extratop {
    padding-top: 50px;
}
@-webkit-keyframes circle {
    0% {
        width: 13px;
        height: 14px;
        background-color: white;
    }
    50% {
        width: 50px;
        height: 57px;
        background-color: rgb(112, 112, 112);
        border: 4px solid rgb(39, 39, 39);
    }
}
@keyframes circle {
    0% {
        width: 13px;
        height: 14px;
        background-color: white;
    }
    50% {
        width: 50px;
        height: 57px;
        background-color: rgb(112, 112, 112);
        border: 4px solid rgb(39, 39, 39);
    }
}
@-webkit-keyframes reversecircle {
    0%{
        width: 40px;
        height: 40px;
        background-color: rgb(112, 112, 112);
        -webkit-animation-name: circle;
                animation-name: circle;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        border: 4px solid rgb(37, 37, 37);
    }
    50%{
        width: 50px;
        height: 60px;
        background-color: rgb(112, 112, 112);
        border: 4px solid rgb(39, 39, 39);
    }
}
@keyframes reversecircle {
    0%{
        width: 40px;
        height: 40px;
        background-color: rgb(112, 112, 112);
        -webkit-animation-name: circle;
                animation-name: circle;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        border: 4px solid rgb(37, 37, 37);
    }
    50%{
        width: 50px;
        height: 60px;
        background-color: rgb(112, 112, 112);
        border: 4px solid rgb(39, 39, 39);
    }
}
@media (max-width: 750px){
    .abouttexthidden, .abouttextshown {
        font-size: 20px;
    }
    .aboutmain {
        height: 120vh;
    }
}
@media (max-width: 560px){
    .abouttexthidden, .abouttextshown {
        font-size: 16px;
    }
}
@media (max-width: 450px){
    .abouttexthidden, .abouttextshown {
        font-size: 14px;
    }
}
@media (max-height: 625px){
    #aboutmain {
        height: 130vh;
    }
}
@media (max-height: 575px){
    #aboutmain {
        height: 140vh;
    }
}
@media (max-height: 525px){
    #aboutmain {
        height: 150vh;
    }
}
@media (max-height: 500px){
    #aboutmain {
        height: 160vh;
    }
}
@media (max-height: 475px){
    #aboutmain {
        height: 170vh;
    }
}
@media (max-height: 425px){
    #aboutmain {
        height: 180vh;
    }
}
@media (max-height: 400px){
    #aboutmain {
        height: 190vh;
    }
}
@media (max-height: 375px){
    #aboutmain {
        height: 200vh;
    }
}
@media (max-height: 350px){
    #aboutmain {
        height: 210vh;
    }
}
@media (max-height: 325px){
    #aboutmain {
        height: 220vh;
    }
}
@media (max-height: 300px){
    #aboutmain {
        height: 230vh;
    }
}


#projectsmain {
    padding-top: 100px;
    min-height: calc(100vh - 75px);
    background-image: -webkit-gradient(linear, right bottom, left top, color-stop(40%, rgb(255, 255, 255)), to(rgb(192, 192, 192)));
    background-image: linear-gradient(to top left, rgb(255, 255, 255) 40%, rgb(192, 192, 192));
    background-color: transparent;
    z-index: 1;
    position: relative;
}
#projects {
    position: absolute;
    top: -150px;
}
.projecttitle {
    font-size: 50px;
    font-weight: 700;
    color: rgb(65, 65, 65);
    font-style: italic;
    font-family: 'Montserrat';
    font-weight: 900;
    letter-spacing: 2px;
}
.projecttitledescr {
    padding-top: 10px;
    font-style: italic;
    color: rgb(65, 65, 65);
    letter-spacing: 1px;
}
.projectboxblockone, .projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
    display: flex;
    justify-content: space-around;
    padding: 0 50px;
}
.projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
    padding-top: 100px;
}
.projectboxblockone {
    padding-top: 200px;
}
.projectboxblockeight {
    padding-bottom: 300px;
}
@media (max-width: 1075px){
    .projectboxblockone, .projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
        flex-direction: column;
    }
    .projectscontainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1000px){
    .projectboxblockeight {
        padding-bottom: 150px;
    }
}
@media (max-width: 750px){
    #projects {
        top: -150px;
    }
}
@media (max-width: 580px){
    .projectboxblockone, .projectboxblocktwo, .projectboxblockthree, .projectboxblockfour, .projectboxblockfive, .projectboxblocksix, .projectboxblockseven, .projectboxblockeight {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width: 500px){
    .projectboxblockone {
        padding-top: 150px;
    }
}

.projectboxmain, .projectboxreversemain {
    height: 450px;
    width: 475px;
    display: flex;
    flex-direction: column;
    color: rgb(180, 176, 176);
    position: relative;
    background-color: transparent;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.projectboxreversemain {
    height: 460px;
}
.projectboxmain, .projectboxreversemain {
    background-color: transparent;
}
.projectname {
    font-size: 28px;
    padding-top: 25px;
    color: rgb(78, 78, 78);
    padding-bottom: 10px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: italic;
    padding-left: 10px;
    padding-right: 10px;
}
.projectaddress {
    padding-bottom: 25px;
}
.projectimage {
    height: 355px;
    width: 100%;
}
img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
@media (max-width: 1075px){
    .projectboxmain {
        margin-bottom: 125px;
    }
    .projectboxreversemain {
        margin-bottom: 25px;
    }
}
@media (max-width: 500px){
    .projectboxmain, .projectboxreversemain {
        height: 475px;
        width: 100vw;
    }
    .projectimage {
        height: 370px;
    }
}
@media (max-width: 400px){
    .projectboxmain, .projectboxreversemain {
        height: 410px;
    }
    .projectimage {
        height: 310px;
    }
    .projectname {
        font-size: 25px;
    }
    .projecttitle {
        font-size: 45px;
    }
}
@media (hover: hover) and (pointer: fine){
    .projectboxmain:hover, .projectboxreversemain:hover {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
        -webkit-transition: 0.7s;
        transition: 0.7s;
    }
    .projectboxmain:hover {
        box-shadow: 0px 14px 10px 10px rgb(161, 161, 161);
    }
    .projectboxreversemain:hover {
        box-shadow: 0px 8px 12px 10px rgb(161, 161, 161);
    }
}
.landingcovermain {
    background-image: url("/static/media/RPI Home Image.66dac46f.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;
}
.landingcovermainsunset {
    background-image: url("/static/media/Desert Sunset.56c2f845.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;
}
.landingcovermainnight {
    background-image: url("/static/media/Desert Night.ad85ec9e.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;
}
.landingcovertwomain {
    background-color: rgb(206, 206, 206);
    height: 120vh;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: -98;
}
@media (max-width: 750px){
    .landingcovertwomain {
        height: 120vh;
    }
}
@media (max-height: 625px){
    .landingcovertwomain {
        height: 130vh;
    }
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 115vh;
    }
}
@media (max-height: 575px){
    .landingcovertwomain {
        height: 140vh;
    }
}
@media (max-height: 525px){
    .landingcovertwomain {
        height: 150vh;
    }
}
@media (max-height: 500px){
    .landingcovertwomain {
        height: 160vh;
    }
}
@media (max-height: 490px){
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 130vh;
    }
}
@media (max-height: 475px){
    .landingcovertwomain {
        height: 170vh;
    }
}
@media (max-height: 425px){
    .landingcovertwomain {
        height: 180vh;
    }
}
@media (max-height: 400px){
    .landingcovertwomain {
        height: 190vh;
    }
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 140vh;
    }
}
@media (max-height: 375px){
    .landingcovertwomain {
        height: 200vh;
    }
}
@media (max-height: 350px){
    .landingcovertwomain {
        height: 210vh;
    }
}
@media (max-height: 325px){
    .landingcovertwomain {
        height: 220vh;
    }
    .landingcovermain, .landingcovermainnight, .landingcovermainsunset {
        height: 150vh;
    }
}
@media (max-height: 300px){
    .landingcovertwomain {
        height: 230vh;
    }
}
.bottomnavbarmain {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75px;
    width: 100%;
    background-color: rgba(27, 27, 27, 0.616);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(65, 64, 64);
    font-size: 35px;
    font-family: 'Montserrat';
    -webkit-transition: text-shadow 0.7s ease-in-out;
    transition: text-shadow 0.7s ease-in-out;
    font-style: italic;
}
.bottomnavbarmainsticky {
    position: fixed;
    top: 75px;
    height: 75px;
    width: 100%;
    z-index: 100;
    -webkit-transition: text-shadow 0.7s ease-in-out, background-color 3s ease-in-out;
    transition: text-shadow 0.7s ease-in-out, background-color 3s ease-in-out;    
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(65, 64, 64);
    font-size: 35px;
    font-family: 'Montserrat';
    font-style: italic;
}
.icons {
    height: 30px;
    width: auto;
    margin-top: 15px;
    margin-bottom: 10px;
}
.abouttag, .projectstag, .contacttag {
    display: flex;
    flex-direction: column;
    color: rgb(192, 192, 192);
    z-index: 99;
    padding-left: 25px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.abouttagactive, .projectstagactive, .contacttagactive {
    display: flex;
    flex-direction: column;
    color: rgb(255, 1, 1);
    z-index: 99;
    padding-left: 25px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.abouttag:hover, .projectstag:hover, .contacttag:hover {
    color: rgb(122, 122, 122);
    -webkit-transform: scale(1.05) skewX(-2deg) translateX(2px);
            transform: scale(1.05) skewX(-2deg) translateX(2px);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.abouttagactive:hover, .projectstagactive:hover, .contacttagactive:hover {
    -webkit-transform: scale(1.05) skewX(-2deg) translateX(2px);
            transform: scale(1.05) skewX(-2deg) translateX(2px);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
#bottomnavbarcontainer {
    position: relative;
}
.transitionnavbarhidden {
    position: absolute;
    height: 75px;
    width: 100%;
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(46, 46, 46)), color-stop(rgb(0, 0, 0)), to(rgb(46, 46, 46)));
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    opacity: 0;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    z-index: -1;
}
.transitionnavbar {
    position: absolute;
    height: 75px;
    width: 100%;
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(46, 46, 46)), color-stop(rgb(0, 0, 0)), to(rgb(46, 46, 46)));
    background-image: linear-gradient(to right, rgb(46, 46, 46), rgb(0, 0, 0), rgb(46, 46, 46));
    opacity: 1;
    z-index: -1;
}
@media (max-width: 575px){
    .bottomnavbarmain, .bottomnavbarmainsticky {
        font-size: 25px;
    }
    .abouttag, .projectstag, .contacttag, .abouttagactive, .projectstagactive, .contacttagactive {
        padding-left: 0px;
    }
    .abouttag:hover, .projectstag:hover, .contacttag:hover, .abouttagactive:hover, .projectstagactive:hover, .contacttagactive:hover {
        -webkit-transform: none;
                transform: none;
    }
}
@media (max-width: 425px){
    .bottomnavbarmain, .bottomnavbarmainsticky {
        font-size: 20px;
    }
}

